.hamburger-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 651px) {
  .hamburger-icon {
    display: none;
  }
}

.menu-items-title {
  display: flex;
  align-items: center;

  .menu-item-icon {
    width: 32px;
    height: 32px;
    color: #fff;
    margin-right: 0.5rem;
  }
}

.login-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 30px;
}

.menu-items-user {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.top-menu-content-item-link,
.top-menu-content-item-link:link,
.top-menu-content-item-link:visited,
.top-menu-content-item-link:active {
  text-decoration: none;
  color: #ffffff;
}

.top-menu-content-item-link:hover {
  color: var(--secondary-color);
}